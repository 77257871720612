import React from 'react'
import { graphql, Link } from 'gatsby'
import LayoutRoot from '../components/LayoutRoot'
import styles from '../styles/tag.module.css'
import classnames from 'classnames'
import Img, { FixedObject, FluidObject } from 'gatsby-image'

/**
 *   This is the return format for a single recipe query
 *   Try here http://localhost:8000/___graphql
 
 query MyQuery {
    strapiRecipe {
        created_at
        id
        description
        strapiId
    }
}

Returns 
{
  "data": {
    "strapiRecipe": {
      "created_at": "2021-02-18T20:54:52.663Z",
      "id": "Recipe_1",
      "description": "Healthy oatmeal chocolate chip cookie",
      "strapiId": 1
    }
  },
  "extensions": {}
}
 */

export interface RecipeProps {
    name: string
    strapiId: number
    slug: string
    images: {
      childImageSharp: {
        fixed: FixedObject
        fluid: FluidObject
      }
    }
}

export interface TagProps {
    data: {
        strapiTag: {
            id: number
            strapiId: string
            name: string
            recipes: RecipeProps[]
        }
    }
}

export const query = graphql`
  query Tag($id: Int!) {
    strapiTag(strapiId: { eq: $id }) {
        strapiId
        name
        recipes {
            id
            name
            slug
            images {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
        }
    }
  }
`

const TagPage: React.FC<TagProps> = ({ data }) => {
    const singleTag = data.strapiTag
 
    var recipes: any[] = []
    singleTag.recipes.forEach((item, index) => {
        const url = `/recipes/${item.slug}`
        const image = <Img className={classnames(styles.image)} fluid={item.images.childImageSharp.fluid} />

        recipes.push(
          <a key={index} className={classnames(styles.recipeBlock)} href={url}>
            {image}
        
            <a className={classnames(styles.recipeName)} href={url}>{item.name}</a>  
          </a>
        )
    })

    return (
      <LayoutRoot>
        <div className={classnames(styles.main)}>
          <h1 className={classnames(styles.h1)}>{singleTag.name.toUpperCase()}</h1>
          <div className={classnames(styles.recipeGrid)}>
               {recipes}
          </div>
        </div>
      </LayoutRoot>
  )
}

export default TagPage